import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function PP_Navbar({location}) {
    const locationRef = useRef(null)
    const scrollLeft = () => {
        locationRef.current.scrollLeft += -1000;
    };
    const scrollRight = () => {
        locationRef.current.scrollLeft += 1000;
    };
    useEffect(()=>{
        switch(location){
            case 'Resellers':
            case 'Sales Advantage':
                scrollRight()
                break
            default:
                scrollLeft()
        }         
    },[])
    return (
        <div>
            <nav className="cw-bg-green w-100">
                <div className="sm:flex sm:justify-center flex justify-between relative">
                    <FontAwesomeIcon onClick={scrollLeft} className="cursor-pointer my-auto py-3 px-3 text-white text-xs block sm:hidden" icon={faChevronLeft}/>
                    <div style={{scrollBehavior:'smooth'}} ref={locationRef} className="overflow-auto scrollbar-hide whitespace-nowrap">
                        <ul className="inline-flex space-x-2 mx-auto py-2 text-sm font-semibold text-center text-white">
                            <Link to='/partner-program/'><li className={`${location === 'Partner Program' ? 'cw-bg-lightgreen':null} p-1 rounded hover:cw-bg-lightgreen cursor-pointer`}>Partnering for Success</li></Link>
                            <Link to='/partner-program/sales-advantage/'><li className={`${location === 'Sales Advantage' ? 'cw-bg-lightgreen':null} p-1 rounded hover:cw-bg-lightgreen cursor-pointer`}>The CWA Sales Advantage</li></Link>
                            <Link to="/partner-program/value-added-resellers/"><li className={`${location === 'Resellers' ? 'cw-bg-lightgreen':null} p-1 rounded hover:cw-bg-lightgreen cursor-pointer`}>Value Added Resellers</li></Link>
                        </ul>
                    </div>
                    <FontAwesomeIcon onClick={scrollRight} className="cursor-pointer my-auto py-3 px-3 text-white text-xs block sm:hidden" icon={faChevronRight}/>
                </div>    
            </nav>
        </div>
    );
}

export default PP_Navbar;