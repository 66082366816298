import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import PP_Navbar from '../../components/PP_Navbar';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal';
import { Link } from 'gatsby'
import Layout from '../../components/Layout';

function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    let styles ={
        section2:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357204/cwa/assets/images/Partner%20Program/partner-about-bg_m9nc8e.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section4:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357596/cwa/assets/images/Partner%20Program/training-bg1_tzs43s.png')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
    return (
        <div>
            <Layout location={'Partner Program'} title={'Partnering for Success'}>
            {modalOpen?
            <Modal fill="Partner" title="Apply to be a Partner" closeFn={closeModal} />
            :null}
            <PP_Navbar location={'Partner Program'}/>           
            <section className="cw-bg-greengradient text-center py-10 px-5">
                <div className="text-center">
                    <h1 className="text-5xl text-white font-bold pb-2">Partnering for Success</h1>
                    <h2 className="text-3xl text-white font-semibold">The Makings of a Prosperous Career in Sales</h2>
                    <div className='flex justify-center pt-5'>
                        {/* <iframe title="CWA-MS Partner Program Video" src="https://player.vimeo.com/video/125490748?h=014457da2a&amp;dnt=1&amp;app_id=122963" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe> */}
                    </div>

                </div>
            </section>
            <section className="py-10 text-center w-100" style={styles.section2}>
                <div className="max-w-2xl mx-auto px-5">
                    <h3 className="text-white font-bold text-3xl pb-3">Ready for a career that revolves around your life &mdash; and not the other way around?</h3>
                    <p className="font-serif text-white text-xl leading-8">Ready for a career that revolves around your life — and not the other way around? You set your own schedule. How about one that allows you to leverage your exceptional people skills and talent for persuasion? You make your own success. And most importantly of all, one that offers you generous compensation, uncapped bonuses, strong support and upward mobility? You control your income. 
                        If it's time you embarked on a career that offered you all of this and more, then we invite you to come see what a position in our partnership program is all about.</p>
                </div>
            </section>
            <section className="pt-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 flex justify-center">
                        <div className="relative">
                            <img alt='' className="absolute bottom-0" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357358/cwa/assets/images/Partner%20Program/partner-benefits_vvgo0v.png"/>
                        </div>
                        <div className="py-10">
                            <h2 className="cw-text-green font-bold text-3xl">The Perks of Being a Partner</h2>
                            <p className="py-4 text-xl leading-8 font-serif">Why partner with us? The answer is simple — we're committed to your success. We also have A LOT to offer. With uncapped bonuses, 
                                professional training, a top-flight support network and room to grow within the business, our sales partners are subject to some of the industry's best perks.
                            </p>
                            <Link to="/partner-program/sales-advantage"><button className="cw-bg-lightgreen p-3 font-semibold text-white text-lg rounded">Learn more</button></Link>
                        </div>
                    </div>  
                </div>
            </section>

            <section className="pt-10 px-5" style={styles.section4}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10">
                            <h2 className="text-white font-bold text-3xl">The Best Training in the Business</h2>
                            <p className="text-white py-4 text-xl leading-8 font-serif">Whether you have years of experience in the field, or are just now cutting your teeth in sales,
                            the breadth and depth of the offerings in our training program have been calibrated to meet the needs of salespeople of every skill level.
                            </p>
                            {/* <button className="bg-white cw-text-green p-3 font-semibold text-white text-lg rounded">Learn more</button> */}
                        </div>
                        <div className="flex justify-end relative">
                            <img alt='' className="absolute bottom-0" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357618/cwa/assets/images/Partner%20Program/partner-training_kfxx3v.png"/>
                        </div>
                    </div>  
                </div>
            </section>

            <section className="pt-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 flex justify-center">
                        <div className="relative">
                            <img alt='' className="absolute bottom-0" style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649357658/cwa/assets/images/Partner%20Program/partner-residuals_jdpifo.png"/>
                        </div>
                        <div className="py-10">
                            <h2 className="cw-text-green font-bold text-3xl">Lifetime Residuals for Recruiters</h2>
                            <p className="py-4 text-lg leading-8 font-serif">Do you know someone who would make a terrific sales agent? It pays to bring them on board — literally. 
                            CWA pays lifetime residuals to anyone who recruits a sales agent on our behalf.
                            </p>
                            {/* <button className="cw-bg-lightgreen p-3 font-semibold text-white text-lg rounded">Learn more</button> */}
                        </div>
                    </div>  
                </div>
            </section>
            <section className="cw-bg-greengradient">
                <div className="px-5 text-center py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif leading-8">Take the first step toward starting an exciting and rewarding career in our Sales Partner Program.</h3>
                    <button onClick={()=>setModal(true)} className="transition rounded ease-in-out delay-150 border-2 border-white p-2 text-white hover:cw-text-green font-semibold text-lg">Apply to be a Partner</button>
                </div>
            </section>
            </Layout>
        </div>
    );
}
export default Index;